import {Button, Card, CardContent, CardHeader, Grid, Typography} from "@mui/material"
import withStyles from "@mui/styles/withStyles"
import {bool, func, object, string} from "prop-types"
import {PureComponent} from "react"
import {MdEmail as EmailIcon} from "react-icons/md"

import DocumentTitle from "../document-title/document-title"

export class EmailSubscriptions extends PureComponent {
  state = {isResubscribed: false}

  componentDidMount = () => this.props.getContactSubscriptionStatus(this.props.contactId)

  onUnsubscribe = () =>
    this.props.updateContactSubscriptionStatus(this.props.contactId, {
      emailOptedIn: false,
      messageId: this.props.messageId,
      journeyId: this.props.journeyId,
    })

  onResubscribe = () => {
    this.props.updateContactSubscriptionStatus(this.props.contactId, {
      emailOptedIn: true,
      messageId: this.props.messageId,
      journeyId: this.props.journeyId,
    })
    this.setState({isResubscribed: true})
  }

  renderWelcomeBack = () => (
    <Card className={this.props.classes.card}>
      <CardContent className={this.props.classes.centerContent}>
        {this.props.teamId && (
          <img
            alt="team logo"
            className={this.props.classes.teamLogo}
            crossOrigin="anonymous"
            src={`${process.env.REACT_APP_API_URL}/teams/${this.props.teamId}/logo?variant=large`}
          />
        )}
      </CardContent>
      <CardHeader title="Welcome Back!" />
      <CardContent>
        <Typography variant="subtitle1">
          You have re-subscribed and messages from this system will resume.
        </Typography>
      </CardContent>
    </Card>
  )

  renderResubscribe = () => (
    <Card className={this.props.classes.card}>
      <CardContent className={this.props.classes.centerContent}>
        {this.props.teamId && (
          <img
            alt="team logo"
            className={this.props.classes.teamLogo}
            crossOrigin="anonymous"
            src={`${process.env.REACT_APP_API_URL}/teams/${this.props.teamId}/logo?variant=large`}
          />
        )}
      </CardContent>
      <CardHeader title="You have been unsubscribed" />
      <CardContent>
        <Typography variant="subtitle1">
          You won't receive any more messages from this system.
        </Typography>
        <br />
        <Typography variant="subtitle1">
          If unsubscribing was a mistake or you would like to start receiving these messages again,
          please re-subscribe using the button below.
        </Typography>
      </CardContent>
      <CardContent className={this.props.classes.centerContent}>
        <Button color="primary" onClick={this.onResubscribe} variant="contained">
          <EmailIcon className={this.props.classes.icon} />I want to receive email notifications
          again
        </Button>
      </CardContent>
    </Card>
  )

  renderUnsubscribe = () => (
    <Card className={this.props.classes.card}>
      <CardContent className={this.props.classes.centerContent}>
        {this.props.teamId && (
          <img
            alt="team logo"
            className={this.props.classes.teamLogo}
            crossOrigin="anonymous"
            src={`${process.env.REACT_APP_API_URL}/teams/${this.props.teamId}/logo?variant=large`}
          />
        )}
      </CardContent>
      <CardHeader title="You are currently subscribed" />
      <CardContent>
        <Typography variant="subtitle1">
          You will occassionally receive email notifications from us.
        </Typography>
        <br />
        <Typography variant="subtitle1">
          If you don’t want to receive these types of messages anymore, please use the button below
          to confirm.
        </Typography>
      </CardContent>
      <CardContent className={this.props.classes.centerContent}>
        <Button color="primary" onClick={this.onUnsubscribe} variant="contained">
          <EmailIcon className={this.props.classes.icon} />I don't want to receive email
          notifications
        </Button>
      </CardContent>
    </Card>
  )

  render() {
    return (
      <Grid
        alignItems="center"
        className={this.props.classes.container}
        container={true}
        justifyContent="space-around"
      >
        <Grid item={true} lg={5} md={9} xs={11}>
          {this.props.emailOptedIn && !this.state.isResubscribed && this.renderUnsubscribe()}
          {!this.props.emailOptedIn && !this.state.isResubscribed && this.renderResubscribe()}
          {this.props.emailOptedIn && this.state.isResubscribed && this.renderWelcomeBack()}
        </Grid>
        <DocumentTitle title="Subscriptions" />
      </Grid>
    )
  }
}

EmailSubscriptions.propTypes = {
  classes: object.isRequired,
  contactId: string.isRequired,
  emailOptedIn: bool.isRequired,
  getContactSubscriptionStatus: func.isRequired,
  journeyId: string.isRequired,
  messageId: string.isRequired,
  teamId: string.isRequired,
  updateContactSubscriptionStatus: func.isRequired,
}

const styles = () => ({
  card: {
    paddingBottom: "50px",
  },
  container: {
    height: "100vh",
  },
  icon: {
    marginRight: "5px",
  },
  centerContent: {
    display: "flex",
    justifyContent: "center",
  },
  teamLogo: {
    maxWidth: "100%",
    maxHeight: "200px",
  },
})

export default withStyles(styles)(EmailSubscriptions)
