import shortid from "shortid"

export const getContentBlockType = ({type}) => (/^image\//.test(type) ? "image" : "document")

export const preloadImage = src =>
  new Promise(resolve => {
    const image = new window.Image()

    image.onload = resolve
    image.crossOrigin = "anonymous"
    image.src = src
  })

export const fileToContentBlock = async file => {
  const type = getContentBlockType(file)

  const contentBlock = {
    slug: shortid.generate(),
    type,
    data: {
      id: file.id,
      name: file.name,
      mimetype: file.type,
    },
  }

  if (type === "document")
    return {
      ...contentBlock,
      data: {
        ...contentBlock.data,
        isDownloadDisabled: true,
      },
    }

  let naturalWidth, naturalHeight
  const url = file.url || `${process.env.REACT_APP_API_URL}/files/${file.id}?variant=large`

  // If metadata with image dimensions is present, use it directly
  if (file.meta && file.meta.naturalWidth && file.meta.naturalHeight) {
    naturalWidth = file.meta.naturalWidth
    naturalHeight = file.meta.naturalHeight
  } else {
    // If dimensions are not in metadata, preload the image to get its natural dimensions
    const {
      target: {naturalWidth: loadedNaturalWidth, naturalHeight: loadedNaturalHeight},
    } = await preloadImage(url)
    naturalWidth = loadedNaturalWidth
    naturalHeight = loadedNaturalHeight
  }

  return {
    ...contentBlock,
    data: {
      ...contentBlock.data,
      url,
      naturalWidth,
      naturalHeight,
    },
    styles: {
      textAlign: "center",
    },
  }
}

export default fileToContentBlock
