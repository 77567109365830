import {Box, Button, FormControl, Grid, TextField} from "@mui/material"
import {ThemeProvider} from "@mui/material/styles"
import makeStyles from "@mui/styles/makeStyles"
import {bool, func, object, string} from "prop-types"
import {connect} from "react-redux"

import HeaderLogo from "components/journeys/header-logo"
import {brandingSettings} from "components/teams/branding-settings/branding-settings-context"

import UploadedImage from "../../uploaded-image/uploaded-image"

const LogoSettings = ({
  disabled,
  faviconPreview,
  field,
  logoPreview,
  onChangeFavicon,
  onChangeLogo,
  team,
  muiTheme,
}) => {
  const classes = useStyles()

  return (
    <Grid container spacing={3}>
      <Grid item lg={6} md={12} xs={12}>
        <Box mb={4}>
          {!logoPreview && team && team.isLogoUploaded ? (
            <ThemeProvider theme={muiTheme}>
              <HeaderLogo
                cacheBuster={team.updatedAt}
                src={`${process.env.REACT_APP_API_URL}/teams/${team.id}/logo`}
                variant="large"
              />
            </ThemeProvider>
          ) : logoPreview ? (
            <ThemeProvider theme={muiTheme}>
              <img
                alt=""
                crossOrigin="anonymous"
                src={logoPreview}
                style={{maxWidth: "100%", maxHeight: "100%", ...muiTheme?.custom?.logo}}
              />
            </ThemeProvider>
          ) : (
            <div className={classes.noFile}>no file</div>
          )}
          <div>
            <input
              disabled={disabled}
              id="logo"
              key={logoPreview}
              name="logo"
              onChange={onChangeLogo}
              style={{display: "none"}}
              type="file"
            />
            <label htmlFor="logo">
              <FormControl margin="normal" fullWidth>
                <Button
                  color="primary"
                  component="span"
                  disabled={disabled}
                  size="small"
                  variant="contained"
                >
                  Upload a Logo
                </Button>
              </FormControl>
            </label>
          </div>

          <TextField
            fullWidth
            id="logoWidth"
            label="Logo Width"
            margin="normal"
            type="text"
            helperText="Accepts CSS numbers like 14px, 1.2em, etc"
            {...field(`custom.logo.width`, {defaultValue: "auto"})}
          />

          <TextField
            fullWidth
            id="logoHeight"
            label="Logo Height"
            margin="normal"
            type="text"
            helperText="Accepts CSS numbers like 14px, 1.2em, etc"
            {...field(`custom.logo.height`, {defaultValue: "auto"})}
          />
        </Box>

        <Box mb={4}>
          <div className={classes.faviconWrapper}>
            {!faviconPreview && team && team.isFaviconUploaded ? (
              <UploadedImage
                cacheBuster={team.updatedAt}
                src={`${process.env.REACT_APP_API_URL}/teams/${team.id}/favicon`}
                variant="icon"
              />
            ) : faviconPreview ? (
              <img
                alt=""
                crossOrigin="anonymous"
                src={faviconPreview}
                style={{maxWidth: "100%", maxHeight: "100%"}}
              />
            ) : (
              <div className={classes.noFileFavicon}>no file</div>
            )}
          </div>

          <div>
            <input
              disabled={disabled}
              key={faviconPreview}
              id="favicon"
              name="favicon"
              onChange={onChangeFavicon}
              style={{display: "none"}}
              type="file"
            />
            <label htmlFor="favicon">
              <FormControl margin="normal" fullWidth>
                <Button
                  color="primary"
                  component="span"
                  disabled={disabled}
                  size="small"
                  variant="contained"
                >
                  Upload a Favicon
                </Button>
              </FormControl>
            </label>
          </div>
        </Box>
      </Grid>
    </Grid>
  )
}

LogoSettings.propTypes = {
  disabled: bool,
  faviconPreview: string,
  field: func.isRequired,
  logoPreview: string,
  onChangeLogo: func.isRequired,
  onChangeFavicon: func.isRequired,
  team: object,
  muiTheme: object.isRequired,
}

const useStyles = makeStyles(() => ({
  faviconWrapper: {
    width: 32,
    height: 32,
    marginBottom: 8,
    marginTop: 16,
  },
  noFile: {
    backgroundColor: "lightgray",
    borderRadius: 3,
    padding: 20,
    paddingBottom: "75%",
  },
  noFileFavicon: {
    backgroundColor: "lightgray",
    borderRadius: 3,
    fontSize: 10,
    height: 48,
    padding: 5,
    width: 48,
  },
}))

export default connect(({session}) => ({team: session.team}))(brandingSettings(LogoSettings))
