import {Paper, Typography} from "@mui/material"
import {format} from "date-fns"
import {string} from "prop-types"

const CampaignCoverPage = ({apiUrl, campaignName, teamId}) => (
  <Paper
    component="section"
    name="campaign-coverpage"
    sx={theme => ({
      alignItems: "center",
      display: "flex",
      flexDirection: "column",
      marginBottom: theme.spacing(2),
      padding: `${theme.spacing(8)} ${theme.spacing(4)}`,
    })}
  >
    <img
      alt="Financial institution logo"
      src={`${apiUrl}/teams/${teamId}/logo?variant=original`}
      style={{maxWidth: "800px"}}
      crossOrigin="anonymous"
    />
    <Typography component="h1" sx={theme => ({marginTop: theme.spacing(8)})} variant="h2">
      {campaignName}
    </Typography>
    <Typography variant="body1">
      Campaign approval package compiled {format(Date.now(), "MMM. d, yyyy")}
    </Typography>
  </Paper>
)

CampaignCoverPage.propTypes = {
  apiUrl: string.isRequired,
  campaignName: string.isRequired,
  teamId: string.isRequired,
}

export default CampaignCoverPage
