import {Button, CircularProgress, FormControl, Input, Link, Typography} from "@mui/material"
import withTheme from "@mui/styles/withTheme"
import cx from "classnames"
import snakeCase from "lodash/snakeCase"
import {func, object, shape, string} from "prop-types"
import {Component} from "react"
import {
  MdCheckCircle as CheckCircleIcon,
  MdPhoneIphone as MobilePhoneIcon,
  MdPhonelinkErase as PhonelinkEraseIcon,
} from "react-icons/md"
import {connect} from "react-redux"

import AppendPluginMenu from "components/content-block-editor/append-plugin-menu"
import SortableDragHandle from "components/content-block-editor/drag-handle"

import {LOADING_STATE_COMPLETED, LOADING_STATE_FAILED} from "actions/loading-state-actions"

import isMobile from "lib/browser/is-mobile"
import mobileOS from "lib/browser/mobile-os"

import BrandedButton from "../../../branded-button/branded-button"
import {journeyContext} from "../../../journeys/journey-context"
import MuiIcon from "../../../mui-icon"
import PhoneTextMask from "../../../phone-text-mask/phone-text-mask"
import {buttonColors} from "../helpers/button-colors"
import {requestLink} from "./mobile-app-download-actions"

const buildQueryParams = params => {
  return Object.entries(params)
    .filter(([key, value]) => !!value)
    .reduce((acc, [key, value], index) => {
      if (index === 0) return `?${snakeCase(key)}=${value}`
      return `${acc}&${snakeCase(key)}=${value}`
    }, "")
}

const buildDownloadUrl = ({contentBlockId, ...params}) =>
  `${
    process.env.REACT_APP_API_URL
  }/mobile-app-downloads/${contentBlockId}/download${buildQueryParams(params)}`

const buildQRUrl = ({contentBlockId, ...params}) =>
  `${
    process.env.REACT_APP_API_URL
  }/mobile-app-downloads/${contentBlockId}/qr-code${buildQueryParams(params)}`

const dropEmptyValues = attrs =>
  Object.entries(attrs).reduce((acc, [key, value]) => (!!value ? {...acc, [key]: value} : acc), {})

class MobileAppDownloadReadonly extends Component {
  constructor() {
    super()

    this.phoneRef = el => (this.phoneMobile = el)
  }

  state = {
    isSending: false,
    isSent: false,
    showQrCode: false,
    phoneMobile: "",
  }

  onClick = () => {
    if (isMobile()) return

    this.setState({isSending: true})

    this.props
      .requestLink(
        dropEmptyValues({
          contactId: this.props.contact?.id,
          journeyId: this.props.journeyId,
          pageId: this.props.pageId,
          contentBlockId: this.props.contentBlock.id,
          phoneMobile: this.state.phoneMobile,
          contentContainerId: this.props.contentBlock.contentContainerId,
          engagementChannelId: this.props.engagementChannelId,
        })
      )
      .then(this.onLinkSent)
  }

  onLinkSent = loadingResult => {
    if (loadingResult.type === LOADING_STATE_COMPLETED)
      this.setState({isSent: true, isSending: false})
    else if (loadingResult.type === LOADING_STATE_FAILED) this.setState({isSending: false})
  }

  onResetSent = () => this.setState({isSent: false, isSending: false})

  onToggleQrCode = () => this.setState(({showQrCode}) => ({showQrCode: !showQrCode}))

  onUpdatePhoneMobile = phoneMobile => this.setState({phoneMobile})

  renderSending = () => (
    <div
      className={cx(this.props.className, this.props.themeClasses.mobileAppDownload, {
        [this.props.themeClasses.mobileAppDownloadIsMobile]: isMobile(),
      })}
      onClick={this.props.onClick}
      style={this.props.style}
    >
      <SortableDragHandle />
      <AppendPluginMenu contentBlock={this.props.contentBlock} />
      <CircularProgress aria-hidden="true" />
      <Typography aria-atomic="true" aria-live="polite" display="block" role="status">
        sending you the link now...
      </Typography>
    </div>
  )

  renderSuccess = () => (
    <div
      className={cx(this.props.className, this.props.themeClasses.mobileAppDownload, {
        [this.props.themeClasses.mobileAppDownloadIsMobile]: isMobile(),
      })}
      onClick={this.props.onClick}
      style={this.props.style}
    >
      <SortableDragHandle />
      <AppendPluginMenu contentBlock={this.props.contentBlock} />
      <MuiIcon
        className={this.props.themeClasses.mobileAppDownloadSuccessIcon}
        icon={<CheckCircleIcon aria-hidden="true" />}
      />
      <Typography aria-atomic="true" aria-live="polite" display="block" role="status">
        We sent a link to your phone!
      </Typography>
      <br />
      <Button onClick={this.onResetSent} size="small">
        Have another phone?
      </Button>
    </div>
  )

  renderUnsupported = () => (
    <div
      className={cx(this.props.className, this.props.themeClasses.mobileAppDownload, {
        [this.props.themeClasses.mobileAppDownloadIsMobile]: isMobile(),
      })}
      onClick={this.props.onClick}
      style={this.props.style}
    >
      <SortableDragHandle />
      <AppendPluginMenu contentBlock={this.props.contentBlock} />
      <MuiIcon
        className={this.props.themeClasses.mobileAppDownloadFailureIcon}
        icon={<PhonelinkEraseIcon aria-hidden="true" />}
      />
      <Typography component="em" display="block">
        We're sorry; your device is not supported.
      </Typography>
    </div>
  )

  render() {
    const {
      contact,
      themeClasses,
      engagementChannelId,
      journeyId,
      pageId,
      contentBlock: {
        id: contentBlockId,
        contentContainerId,
        data: {backgroundColor, iconSrc},
      },
      className,
      customCss,
      onClick,
      style,
    } = this.props
    const link = this.props.contentBlock.data[mobileOS()]
    const qrCodeUrl = buildQRUrl({
      contactId: contact?.id,
      journeyId,
      pageId,
      contentBlockId,
      contentContainerId,
      engagementChannelId,
    })
    const {isSending, isSent, showQrCode} = this.state

    if (isMobile() && !link) return this.renderUnsupported()

    if (isSending) return this.renderSending()

    if (isSent) return this.renderSuccess()

    const buttonProps = {color: "primary"}
    if (backgroundColor) buttonProps.style = buttonColors({backgroundColor})

    if (isMobile())
      buttonProps.href = buildDownloadUrl({
        contactId: contact?.id,
        journeyId,
        pageId,
        contentBlockId,
        contentContainerId,
        engagementChannelId,
      })

    return (
      <div
        className={cx(className, themeClasses.mobileAppDownload, {
          [themeClasses.mobileAppDownloadIsMobile]: isMobile(),
        })}
        onClick={onClick}
        style={style}
      >
        <SortableDragHandle />
        <AppendPluginMenu contentBlock={this.props.contentBlock} />
        <style>{customCss}</style>
        <div className={themeClasses.mobileAppDownloadInputLine}>
          <Typography className={themeClasses.mobileAppDownloadInputLabel} component="span">
            Your mobile number
          </Typography>{" "}
          <FormControl>
            <Input
              className={themeClasses.mobileAppDownloadInput}
              inputComponent={PhoneTextMask}
              inputProps={{
                "aria-label": "Enter your phone number to receive a link to the mobile app",
                onAccept: this.onUpdatePhoneMobile,
                value: this.state.phoneMobile,
              }}
            />
          </FormControl>
        </div>
        <BrandedButton
          color="primary"
          onClick={this.onClick}
          size="large"
          variant="contained"
          {...buttonProps}
        >
          {isSending && <CircularProgress size={20} style={{color: "black", marginRight: 10}} />}
          {isMobile() ? "Install the App" : "Send me the link"}
          <MuiIcon
            className={themeClasses.mobileAppDownloadPhoneIcon}
            icon={
              iconSrc ? (
                <img
                  alt=""
                  aria-hidden="true"
                  className="icon"
                  crossOrigin="anonymous"
                  src={iconSrc}
                />
              ) : (
                <MobilePhoneIcon aria-hidden="true" />
              )
            }
          />
        </BrandedButton>
        <Typography
          className={themeClasses.mobileAppDownloadFinePrint}
          component="small"
          display="block"
        >
          Standard data fees and text messaging rates may apply.
        </Typography>
        {!isMobile() && !showQrCode && (
          <Link onClick={this.onToggleQrCode} style={{cursor: "pointer"}}>
            Want to use a QR code instead?
          </Link>
        )}
        {showQrCode && (
          <img
            alt="QR Code"
            className={cx("qr-code", themeClasses.mobileAppDownloadQrCode)}
            onClick={this.onToggleQrCode}
            src={qrCodeUrl}
          />
        )}
      </div>
    )
  }
}

MobileAppDownloadReadonly.propTypes = {
  contact: object,
  journeyId: string,
  pageId: string,
  requestLink: func.isRequired,
  themeClasses: object.isRequired,
  contentBlock: shape({
    slug: string.isRequired,
    data: shape({
      backgroundColor: string,
      MobileOSAndroid: string,
      MobileOSWindowsPhone: string,
      MobileOSiOs: string,
    }),
  }),
  customCss: string,
  engagementChannelId: string,
  onClick: func,
  className: string,
  style: object,
  theme: object,
}

export default connect(null, {requestLink})(withTheme(journeyContext(MobileAppDownloadReadonly)))
