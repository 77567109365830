import {CircularProgress} from "@mui/material"
import withStyles from "@mui/styles/withStyles"
import {bool, func, object, shape, string} from "prop-types"
import {useEffect, useState} from "react"
import {FaCheck as CheckIcon} from "react-icons/fa"

import BrandedButton from "../branded-button/branded-button"

export const SaveButton = ({
  iconSrc,
  onClick,
  classes,
  disabled,
  submitting,
  failed,
  stateLabels = {},
  ...buttonProps
}) => {
  const [saved, setSaved] = useState(false)

  useEffect(() => {
    const clearConfirm = () => setSaved(false)

    if (submitting) setSaved(true)

    let timeoutId = null

    if (!submitting && saved && !failed) timeoutId = setTimeout(clearConfirm, 3000)
    else if (!submitting && failed) setSaved(false)

    return () => timeoutId && clearTimeout(timeoutId)
  }, [failed, saved, submitting])

  return (
    <BrandedButton
      classes={{label: classes.label}}
      color="primary"
      disabled={disabled || submitting}
      onClick={onClick}
      type="submit"
      variant="contained"
      data-testid="save-button"
      {...buttonProps}
    >
      {submitting ? (
        <>
          <CircularProgress className={classes.icon} size={14} />
          {stateLabels.submitting || "Saving..."}
        </>
      ) : saved && !failed ? (
        <>
          <CheckIcon className={classes.icon} />
          {stateLabels.saved || "Saved!"}
        </>
      ) : (
        <>{stateLabels.default || "Save"}</>
      )}
      {iconSrc && (
        <img alt="" aria-hidden="true" className="icon" crossOrigin="anonymous" src={iconSrc} />
      )}
    </BrandedButton>
  )
}

SaveButton.propTypes = {
  classes: object.isRequired,
  disabled: bool,
  failed: bool,
  iconSrc: string,
  onClick: func,
  stateLabels: shape({
    default: string,
    submitting: string,
    saved: string,
  }),
  submitting: bool.isRequired,
}

const styles = theme => ({
  label: {
    display: "flex",
    alignItems: "center",
    transition: theme.transitions.create("all"),
  },
  icon: {
    marginRight: theme.spacing(1),
  },
})

export default withStyles(styles)(SaveButton)
